import { makeAutoObservable } from "mobx";

/* for backend API calls */
import axios from "common/ServerConnection";

/* Pull user information */
import CurrentUser from "common/CurrentUser";


interface OllamaApiResponse {
    model: string;
    created_at: string;
    response: string;
    text: string;
    vid: string;
    pic: string;
    done: boolean;
  }

interface llmResponse {
  success: boolean;
  error_msg: string;
  payload: OllamaApiResponse;    
}

class Store {
    userInput = "";
    prompts: string[] = [""];
    answers: string[] = [""        
    ];
    textDisplay = "";
    picURL = "";
    vidURL = "";
    renderCustom = false;

    constructor() {
      makeAutoObservable(this);      
      if(CurrentUser.info !== null) {
        console.log("CurrentUser =", CurrentUser.info.first_name);
        let first_name = CurrentUser.info.first_name;               
        this.setAnswers(`## Good morning, ${first_name} \n This is what your schedule looks like: \n * 9am: Breakfast with [Dr. Terry Smith](https://riversidehealth.org/) \n * 10:45am: Dr. Jennifer Snyder @ Montefiore \n * 12pm: Lunch with Dr. Tom Burns @ [Weill Cornell](https://maps.app.goo.gl/ZcTLCgXjuCHjECNS7) \n`);
      }      
    }
    
    setUserInput(value: string) {
        this.userInput = value;
    }
    
    addPrompt(prompt: string) {
        this.prompts.push(prompt);
    }

    setAnswers(newAnswer: string) {
      this.answers = [newAnswer];
    }
    
    addAnswer(answer: string) {
    this.answers.push(answer);
    }

    setText(text: string) {
    this.textDisplay = text;
    }

    setPic(pic: string) {
    this.picURL = pic;
    }

    setVid(vid: string) {
    this.vidURL = vid;
    }

    setRenderCustom(val: boolean) {
      this.renderCustom = val;
    }

    async sendMessageToFlask(msg: string): Promise<OllamaApiResponse> {
        const requestBody = {
          prompt: msg
        };
    
        const apiUrl = process.env.REACT_APP_API_URL;
        console.log('API URL:', apiUrl);
    
        try {
          const response = await fetch(`${apiUrl}/langgrapho_gen`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
          });
    
          if (response.ok) {
            const result: OllamaApiResponse = await response.json();
            console.log('Server response:', result);
            return result;
          } else {
            console.error('Server error:', response.status, response.statusText);
            return {
              model: '',
              created_at: '',
              response: 'Error occurred',
              text: '',
              vid: 'nan',
              pic: 'nan',
              done: false,
            };
          }
        } catch (error) {
          console.error('Network error:', error);
          return {
            model: '',
            created_at: '',
            response: "I'm not connected to the internet -- I can't answer your question at this point...",
            text: '',
            vid: '',
            pic: '',
            done: false,
          };
        }
    }

    async sendMsgToLLMService(msg: string): Promise<OllamaApiResponse> {      

      try {
        const response = await axios.post<llmResponse>('/api/e/call_dummy_microservice', {'message': msg});        
        console.log(response);                

        if (response.status >= 200 && response.status < 300 && response.data.success === true) {          
          //const result: llmResponse = response.data;
          const result: OllamaApiResponse = response.data.payload;
          console.log('Server response:', result);

          return result;          
        } else {
          console.error('Server error:', response.status, response.statusText);
          return {
            model: '',
            created_at: '',
            response: 'Error occurred',
            text: '',
            vid: 'nan',
            pic: 'nan',
            done: false,
          };
        }
        
      } catch (error: any) {
        console.error('Network error:', error);
        return {
          model: '',
          created_at: '',
          response: "I'm not connected to the internet -- I can't answer your question at this point...",
          text: '',
          vid: '',
          pic: '',
          done: false,
        };
      }
  }

}

export const store = new Store();