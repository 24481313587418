import React, {useEffect, useRef, useState} from 'react';
import ShowTyping from "../components/ShowTyping"; 

import { observer } from 'mobx-react-lite';
import { store } from '../stores/Store';

import cx from "classnames";
import styles  from '../demobot.module.css';  

/* Added for fixing the TextArea so it sticks to the bottom  of mobile view */
import CustomInput from '../components/CustomInput';
import useWindowSize from '../hooks/useWindowSize';

/* Added for voice activation */
import FloatingButton from '../components/FloatingButton';
import VoiceInput from '../components/VoiceInput';



const Home: React.FC = observer(() => { 
    /* Added for fixing textArea */
    const { width } = useWindowSize();  
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);
    const mobileBreakpoint = 414;

    useEffect(() => {
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [store.userInput]);

    /* Added for Voice activation aesthetic */
    const [micOn, setMicOn] = React.useState(false);
    const toggleMic = () => {
        setMicOn(!micOn);
    }
  
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      store.setUserInput(event.target.value);
  };

  /* Check to see if things are loading */
  const [isLoading, setIsLoading] = useState(false);

  /* Slide out Frame conditionally */
  /* Moved over to mobx in iframeStore */
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const [isIframeRendered, setIsIframeRendered] = useState(false);
  const [chatClass, setChatClass] = useState('');    
  const [waitingResponse, setwaitingResponse] = React.useState(false);

  const handleToggleIframe = () => {
      setIsIframeVisible(!isIframeVisible);
  };

  const closeIframe = () => {
      setIsIframeVisible(false);    
  };  

  useEffect(() => {
      // When the input changes, set the animation class to slide in or out based on whether the input is empty or not
     if (isIframeVisible) {
        setIsIframeRendered(true);
        setTimeout(() => {
          setChatClass('move-left');
          setAnimationClass('slide-in');            
        },50);
     } else {
        setAnimationClass('slide-out');
        setChatClass('');        
        setTimeout(() => {
          setIsIframeRendered(false);
        },500);
     }
  }, [isIframeVisible]);

  /* End iframe conditional logic */
  
  // Preprocess the markdown to replace [button:...] with <Button text="..."/>
  const preprocessMarkdownToButton = (markdown: string) => {
      return markdown.replace(/\[button:(.*?)\]/g, '[$1](#button:$1)');
  };

  // Added table element in Custom component
  const customComponent = () => {
    return(
          /* |   |    |   |   |
            |:---|:---:|:---:|:---:|
            | 1. Jamie Goodman | [button:Expansion] | [button:Prob:95%] | $91k |
            | 2. Tony Jorgensen, MD | [button:Retention] | [button:Prob:93%] | $85k|
            | 3. Natasha Kristoff | [button:Expansion] | [button:Prob:89%] | $95k |
            | 4. Jeff Gundersen  | [button:Acquisition] |  [button:Prob:85%] | $61k |
            | 5. Jay Gupta MD | [button:Expansion] | [button:Prob:83%] | $84k |
        */
        <table>
          <thead>
            <tr>
              <th> </th>
              <th> </th>
              <th>Success</th>
              <th>CLV</th>          
            </tr>          
          </thead>
          <tbody>
            <tr>
              <td>1. Jamie Goodman</td>
              <td><a className={cx(styles.customButtonExpansion)} href="#" onClick={() => handleToggleIframe()}>Expansion</a></td>
              <td><a className={cx(styles.customButtonProb)} href="#" onClick={() => handleToggleIframe()}>Prob: 95%</a></td>
              <td>$91k</td>
            </tr>
            <tr>
              <td>2. Tony Jorgensen MD</td>
              <td><a className={cx(styles.customButtonRetention)} href="#" onClick={() => handleToggleIframe()}>Retention</a></td>
              <td><a className={cx(styles.customButtonProb)} href="#">Prob: 93%</a></td>
              <td>$85k</td>
            </tr>
            <tr>
              <td>3. Natasha Kristoff</td>
              <td><a className={cx(styles.customButtonExpansion)} href="#" onClick={() => handleToggleIframe()}>Expansion</a></td>
              <td><a className={cx(styles.customButtonProb)} href="#">Prob: 89%</a></td>
              <td>$95k</td>
            </tr>
            <tr>
              <td>4. Jeff Gundersen</td>
              <td><a className={cx(styles.customButtonAcquisition)} href="#" onClick={() => handleToggleIframe()}>Acquisition</a></td>
              <td><a className={cx(styles.customButtonProb)} href="#">Prob: 85%</a></td>
              <td>$61k</td>
            </tr>
            <tr>
              <td>5. Jay Gupta MD</td>
              <td><a className={cx(styles.customButtonExpansion)} href="#" onClick={() => handleToggleIframe()}>Expansion</a></td>
              <td><a className={cx(styles.customButtonProb)} href="#">Prob: 83%</a></td>
              <td>$84k</td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
    )    
  }

  const handleSubmit = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => { 
      if (event.key === 'Enter' && store.userInput.trim()) {         
        setIsLoading(true);
        console.log('eventKey:', event.key);
        const userInput = store.userInput;
        
        /* Clear textarea field after submitting  */        
        store.setUserInput(""); // Clears it via the MobX store.

        store.addPrompt(userInput);
        
        if (userInput === "I'm going to Albany next week, who should I talk to?" || userInput === "asd") {
          const bakedMarkdwon = preprocessMarkdownToButton(`Of course, you had discussed with your director to use the Acquisition-Retention-Expansion (ARE) framework to prioritize, these are the best 5 people you should talk to: \n  
          `)        
          store.addAnswer(bakedMarkdwon);
          store.setRenderCustom(true);

        } else if (userInput === "Expansion") {
          handleToggleIframe()        
          store.setRenderCustom(false);
        } else {
          store.setRenderCustom(false);          
          const ollamaResponse = await store.sendMsgToLLMService(userInput);                    
          // For some reason the text response from ollamaResponse is escaping \n's by adding an extra "\".  Let's properly escape it for rednering in markdown.
          if( ollamaResponse.text !== undefined ) {
              let mkdwnText = ollamaResponse.text.replace(/\\n/g, '\n');
              console.log("msg=", ollamaResponse.response);                
              store.setText(mkdwnText);
              console.log("txt=", mkdwnText);
              store.setPic(ollamaResponse.pic);
              store.setVid(ollamaResponse.vid);
          }
          store.addAnswer(ollamaResponse.response);          
        }

        /* Clear textarea field after submitting process is done  */        
        store.setUserInput(""); // Clears it via the MobX store.
        setIsLoading(false);   
      }
  };

  const handleVoiceSubmit = async (voiceInput: string) => { 
      setwaitingResponse(true);
      setIsLoading(true);
      const userInput = voiceInput;      
      store.setUserInput(''); // Clear input field after submitting      
      store.addPrompt(userInput);
      
      if (userInput === "I'm going to Albany next week, who should I talk to?" || userInput === "asd") {
        const bakedMarkdwon = preprocessMarkdownToButton(`Of course, you had discussed with your director to use the Acquisition-Retention-Expansion (ARE) framework to prioritize, these are the best 5 people you should talk to: \n  
          `)        
          store.addAnswer(bakedMarkdwon);
          store.setRenderCustom(true);
      } else if (userInput === "Expansion") {
          handleToggleIframe()        
          store.setRenderCustom(false);
      } else {
        store.setRenderCustom(false);        
        //await new Promise(resolve => setTimeout(resolve, 3000));

        const ollamaResponse = await store.sendMsgToLLMService(userInput);              
        
        if( ollamaResponse.text !== undefined ) {
          // For some reason the text response from ollamaResponse is escaping \n's by adding an extra "\".  Let's properly escape it for rednering in markdown.
          let mkdwnText = ollamaResponse.text.replace(/\\n/g, '\n');
          console.log("msg=", ollamaResponse.response);                
          store.setText(mkdwnText);
          console.log("txt=", mkdwnText);
          store.setPic(ollamaResponse.pic);
          store.setVid(ollamaResponse.vid);
        }
        store.addAnswer(ollamaResponse.response);        
      }

      /* Clear textarea field after submitting process is done  */        
      store.setUserInput(""); // Clears it via the MobX store.
      setwaitingResponse(false);
      setIsLoading(false);
      
    
  };


  return(
      <main className={styles.demobotAppContent}>
          <div className={styles.mainContainer}>
              <div className={cx(styles.chatContainer, styles[chatClass])}>
                  <div className={styles.prompt}>
                  {store.prompts[store.prompts.length -1 ]}
                  </div>
                  <div className={styles.glass}>        
                      { <ShowTyping 
                          msg={ store.answers[store.answers.length -1 ] }
                          txt={ store.textDisplay }
                          pic={ store.picURL }
                          vid={ store.vidURL }                  
                          custom={customComponent}
                          renderCustom={ store.renderCustom }
                          loading = {isLoading} 
                      /> }
                  </div>        
                  <p>
                    {(width > 480)?
                        (micOn)? 
                        <VoiceInput waitingResponse={waitingResponse} handleSubmit={handleVoiceSubmit} /> :
                        <textarea 
                          {...({ enterkeyhint: 'send' } as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
                          ref={textareaRef}
                          className={styles.slickPrompt}                          
                          placeholder="Remind me of my routine"              
                          value={store.userInput}
                          onChange={handleInputChange}
                          onKeyDown={handleSubmit} // This triggers when we submit or Enter                                          
                          autoFocus
                      /> 
                    : null
                    }                      
                  </p>
              </div>
              {isIframeRendered && (        
              <div className={cx(styles.iframeContainer, styles[animationClass])} >
              <button className={styles.closeButton} onClick={closeIframe}>×</button>
              <iframe 
                  style={{ width: '100%', height: '100%', border: 'none' }}
                  allowFullScreen={true}
                  src="https://app.eisengard.ai/shared_canvas_module.html?remote_moduleid=Fy5nPC5cRpeLmr8SIp%2F%2FeA">        
              </iframe>
              </div>
              )}            
          </div>
          <div className={styles.bottomDiv}>
          {(width <= 480)?
            <CustomInput   
                micOn={micOn}
                setMicOn={setMicOn} 
                waitingResponse={waitingResponse}                
                handleSubmit={handleVoiceSubmit}
                onKeyDown={handleSubmit} // This triggers when we submit or Enter              
                value={store.userInput}
                onChange={handleInputChange}
            /> : 
            <div className={styles.floatingButtonWrapper}>
              <FloatingButton waitingResponse={waitingResponse} toggle={toggleMic} micOn={micOn}/>
            </div>
          }
          </div>
      </main>
  );
});   

export default Home;